$(function() {
  $.validator.setDefaults({ ignore: ':hidden:not(select)' });
  $('.create-right-form, .edit-right-form').validate({
    messages: {
      exclusivity_type: 'Please select an Exclusivity Type',
      'territories[]': 'Please select at least one Territory in the field below',
      'medias[]': 'Please select at least one Right in the field below',
      'languages[]': 'Please select at least one Language in the field below',
    },
  });
});
