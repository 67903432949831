import { Controller } from '@hotwired/stimulus'

const viewConnect = function() {
  $('#form-submit').on('click', function(ev){
    ev.preventDefault()

    const organizationTypeSelectInvalid = !document.querySelector("#organization_type").value

    if (organizationTypeSelectInvalid) {
      let organizationTypeLi = document.createElement('li')
      organizationTypeLi.innerHTML = 'An Organization Type selection is required'
      document.querySelector('#validation-warning ul').appendChild(organizationTypeLi)
    }

    const formValid = (
      !organizationTypeSelectInvalid
    )
    
    if (formValid) {
      document.querySelector('#form-organization').submit()
    } else {
      document.querySelector('#validation-warning').style.display = "block"
    }
  })
}

export default class extends Controller {
  connect() {
    const controllerAction = this.data.get('action')
    switch (controllerAction) {
      case 'edit':
        viewConnect()
        break
      case 'new':
        viewConnect()
        break
      case 'index':
        break
    }
  }
}
