import { Controller } from "@hotwired/stimulus";
import TomSelect from "tom-select";

const viewConnect = function () {
  const selectSettings = {
    plugins: ["remove_button", "input_autogrow", "clear_button"],
  };
  new TomSelect("#vendor_id", selectSettings);
  new TomSelect("#itemable_type", selectSettings);
  new TomSelect("#title_id", selectSettings);
  new TomSelect("#episode_id", selectSettings);
};

// Connects to data-controller="vendor-items"
export default class extends Controller {
  static targets = [
    "typeSelectField",
    "titleSelect",
    "titleSelectField",
    "episodeSelect",
    "episodeSelectField",
  ];

  connect() {
    const controllerAction = this.data.get("action");
    switch (controllerAction) {
      case "edit":
        viewConnect();
        this._showItemableSelect(this.typeSelectFieldTarget.value);
        break;
      case "new":
        viewConnect();
        this._showItemableSelect(this.typeSelectFieldTarget.value);
        break;
      case "index":
        break;
    }
  }

  typeSelectChange(event) {
    this._showItemableSelect(event.target.value);
  }

  _showItemableSelect(selectedType) {
    if (selectedType === "Title") {
      this.episodeSelectFieldTarget.disabled = true;
      this.episodeSelectTarget.classList.add("d-none");
      this.titleSelectFieldTarget.disabled = false;
      this.titleSelectTarget.classList.remove("d-none");
    } else if (selectedType === "Episode") {
      this.titleSelectFieldTarget.disabled = true;
      this.titleSelectTarget.classList.add("d-none");
      this.episodeSelectFieldTarget.disabled = false;
      this.episodeSelectTarget.classList.remove("d-none");
    }
  }
}
