import { Controller } from '@hotwired/stimulus'
import TomSelect from 'tom-select'

const viewConnect = function() {
  const selectSettings = {
    plugins: ['remove_button', 'input_autogrow', 'clear_button']
  }
  new TomSelect('#system_role_ids', selectSettings)
}

export default class extends Controller {
  connect() {
    const controllerAction = this.data.get('action')
    switch (controllerAction) {
      case 'edit':
        viewConnect()
        break
      case 'new':
        viewConnect()
        break
      case 'index':
        break
    }
  }
}
