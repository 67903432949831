import { Controller } from '@hotwired/stimulus'
import TomSelect from 'tom-select'

const viewConnect = function() {
  const selectSettings = {
    plugins: ['remove_button', 'input_autogrow', 'clear_button']
  }
  new TomSelect('#label_id', selectSettings)
  new TomSelect('#project_id', selectSettings)
  new TomSelect('#genres', selectSettings)
  new TomSelect('#origins', selectSettings)
  new TomSelect('#sound_formats', selectSettings)
  new TomSelect('#trailer_sound_formats', selectSettings)
  new TomSelect('#video_formats', selectSettings)
  new TomSelect('#trailer_video_formats', selectSettings)
  new TomSelect('#sound_languages', selectSettings)
  new TomSelect('#caption_languages', selectSettings)
  new TomSelect('#aspect_ratios', selectSettings)
  new TomSelect('#producers', selectSettings)
  new TomSelect('#directors', selectSettings)
  new TomSelect('#writers', selectSettings)
  new TomSelect('#actors', selectSettings)
  new TomSelect('#country_id', selectSettings)
}

const editConnect = function() {
  const selectSettings = {
    allowEmptyOption: true,
    plugins: ['remove_button', 'input_autogrow', 'clear_button']
  }
  new TomSelect('#label_id', selectSettings)
  new TomSelect('#project_id', selectSettings)
  new TomSelect('#genres', selectSettings)
  new TomSelect('#origins', selectSettings)
  new TomSelect('#sound_formats', selectSettings)
  new TomSelect('#trailer_sound_formats', selectSettings)
  new TomSelect('#video_formats', selectSettings)
  new TomSelect('#trailer_video_formats', selectSettings)
  new TomSelect('#sound_languages', selectSettings)
  new TomSelect('#caption_languages', selectSettings)
  new TomSelect('#aspect_ratios', selectSettings)
  new TomSelect('#producers', selectSettings)
  new TomSelect('#directors', selectSettings)
  new TomSelect('#writers', selectSettings)
  new TomSelect('#actors', selectSettings)
  new TomSelect('#country_id', selectSettings)

  const removeButtons = document.getElementsByClassName('remove-button')
  Array.from(removeButtons).forEach(button => {
    button.addEventListener('click', event => {
      event.preventDefault()
      const row = event.target.closest('.row')
      row.remove()
    })
  })

  document.getElementById('addButton').addEventListener('click', function(event) {
    event.preventDefault()

    const localizedNameField = document.getElementById('localized_name')
    const countryIdField = document.getElementById('country_id')
    
    const localizedName = localizedNameField.value.trim()
    const selectedOption = countryIdField.selectedOptions[0]
    const countryId = selectedOption.value
    const countryName = selectedOption.textContent

    if (localizedName && countryId) {
      const entriesContainer = document.getElementById('entriesContainer')

      const row = document.createElement('div')
      row.className = 'row mb-2'

      // Create a hidden input to store the name value
      const hiddenNameInput = document.createElement('input')
      hiddenNameInput.type = 'hidden'
      hiddenNameInput.name = 'localized_names[]'
      hiddenNameInput.value = localizedName

      // Create a hidden input to store the country ID value
      const hiddenCountryIdInput = document.createElement('input')
      hiddenCountryIdInput.type = 'hidden'
      hiddenCountryIdInput.name = 'country_ids[]'
      hiddenCountryIdInput.value = countryId
      
      // Create a visible text element to show name
      const nameDisplayText = document.createElement('div')
      nameDisplayText.className = 'ms-2'
      nameDisplayText.textContent = localizedName

      // Create a visible text element to show name
      const countryDisplayText = document.createElement('div')
      countryDisplayText.className = 'ms-4'
      countryDisplayText.textContent = countryName

      const nameCol = document.createElement('div')
      nameCol.className = 'col-4'
      nameCol.appendChild(nameDisplayText)

      const countryCol = document.createElement('div')
      countryCol.className = 'col-4'
      countryCol.appendChild(countryDisplayText)

      const removeCol = document.createElement('div')
      removeCol.className = 'col-4'

      const removeIcon = document.createElement('i')
      removeIcon.className = 'bi bi-dash-circle'
      
      const removeButton = document.createElement('button')
      removeButton.type = 'button'
      removeButton.className = 'btn btn-danger'
      removeButton.appendChild(removeIcon)
      removeButton.appendChild(document.createTextNode(' Remove'))

      removeButton.addEventListener('click', function() {
        entriesContainer.removeChild(row)
      })

      removeCol.appendChild(removeButton)

      row.appendChild(hiddenNameInput)
      row.appendChild(hiddenCountryIdInput)
      row.appendChild(nameCol)
      row.appendChild(countryCol)
      row.appendChild(removeCol)

      entriesContainer.appendChild(row)
      
      // Reset the fields
      localizedNameField.value = ''
      countryIdField.value = ''
    } else {
      console.error('Error:', error)
    }
  })
}

export default class extends Controller {
  connect() {
    const controllerAction = this.data.get('action')
    switch (controllerAction) {
      case 'edit':
        editConnect()
        break
      case 'new':
        viewConnect()
        break
      case 'index':
        break
    }
  }
}
