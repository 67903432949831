import { Controller } from "@hotwired/stimulus";
import TomSelect from "tom-select";
import Uppy from "@uppy/core";
import Dashboard from "@uppy/dashboard";
import GoldenRetriever from "@uppy/golden-retriever";
import Transloadit from "@uppy/transloadit";

// Connects to data-controller="music-rights"
const newConnect = function () {
  new TomSelect("#licensor_id", {
    allowEmptyOption: true,
    plugins: ["remove_button", "clear_button"],
  });

  new TomSelect("#licensee_id", {
    allowEmptyOption: true,
    plugins: ["remove_button", "clear_button"],
  });

  const mediaGroupsData = document.querySelector(".media-groups-data");
  const mediaGroups = JSON.parse(mediaGroupsData.value);

  const setDifference = function (arrA, arrB) {
    let bSet = new Set(arrB);
    return new Set(arrA.filter((id) => !bSet.has(parseInt(id))));
  };

  let territoryGroupSelect = new TomSelect("#territory_group_id", {
    allowEmptyOption: true,
    plugins: ["remove_button", "clear_button"],
    onInitialize: function () {
      this.clear();
    },
  });

  let countrySelect = new TomSelect("#country_id", {
    allowEmptyOption: true,
    plugins: ["remove_button", "clear_button"],
    onInitialize: function () {
      this.clear();
    },
  });

  let languageSelect = new TomSelect("#language_id", {
    allowEmptyOption: true,
    plugins: ["remove_button", "clear_button"],
    onInitialize: function () {
      this.clear();
    },
  });

  let territoriesSelect = new TomSelect(".tom-select-territories", {
    allowEmptyOption: true,
    plugins: ["remove_button", "input_autogrow", "clear_button"],
    render: {
      no_results: null,
    },
  });

  let excludedTerritoriesSelect = new TomSelect(
    ".tom-select-excluded-territories",
    {
      allowEmptyOption: true,
      plugins: ["remove_button", "input_autogrow", "clear_button"],
      render: {
        no_results: null,
      },
    }
  );

  territoryGroupSelect.on("change", function (value) {
    if (value) {
      countrySelect.clear();
      languageSelect.clear();

      let territoryGroupOption = this.getOption(value);

      territoriesSelect.addOption({
        text: territoryGroupOption.textContent,
        value: value,
      });

      territoriesSelect.addItem(value);
    }
  });

  countrySelect.on("change", function (value) {
    if (value > 0 && languageSelect.getValue() > 0) {
      let languageId = languageSelect.getValue();
      let languageName = languageSelect.getOption(languageId).textContent;

      let countryId = this.getValue();
      let countryOption = this.getOption(countryId);
      let countryName = countryOption.textContent;

      territoriesSelect.addOption({
        text: `${countryName} (${languageName})`,
        value: `territory_${countryId}-${languageId}`,
      });

      territoriesSelect.addItem(`territory_${countryId}-${languageId}`);
    }
  });

  languageSelect.on("change", function (value) {
    if (value > 0 && countrySelect.getValue() > 0) {
      let languageId = value;
      let languageName = this.getOption(languageId).textContent;

      let countryId = countrySelect.getValue();
      let countryOption = countrySelect.getOption(countryId);
      let countryName = countryOption.textContent;

      territoriesSelect.addOption({
        text: `${countryName} (${languageName})`,
        value: `territory_${countryId}-${languageId}`,
      });

      territoriesSelect.addItem(`territory_${countryId}-${languageId}`);
    }
  });

  territoriesSelect.on("item_select", function (item) {
    let itemValue = item.getAttribute("data-value");

    excludedTerritoriesSelect.addOption({
      text: item.textContent.slice(0, -1),
      value: itemValue,
    });

    excludedTerritoriesSelect.addItem(itemValue);
    this.removeItem(itemValue);
    this.removeOption(itemValue);
  });

  excludedTerritoriesSelect.on("item_select", function (item) {
    let itemValue = item.getAttribute("data-value");

    territoriesSelect.addOption({
      text: item.textContent.slice(0, -1),
      value: itemValue,
    });

    territoriesSelect.addItem(itemValue);
    this.removeItem(itemValue);
    this.removeOption(itemValue);
  });

  $(".chosen-select-medias").chosen({
    no_results_text: "No options found",
    width: "500px",
  });

  $(".select-all").on("click", function (ev) {
    ev.preventDefault();

    const id = $(ev.currentTarget).data("id");
    $(`select#${id} option`).prop("selected", true);
    $(`select#${id}`).trigger("chosen:updated");
  });

  $(".deselect-all").on("click", function (ev) {
    ev.preventDefault();

    let id = $(ev.currentTarget).data("id");
    $(`select#${id} option`).prop("selected", false);
    $(`select#${id}`).trigger("chosen:updated");
  });

  $(".media-groups-select").on("click", function (ev) {
    ev.preventDefault();

    $(".media-groups-modal").data("mode", "select");
    $(".media-groups-modal h4").text("Select Media Groups");
  });

  $(".media-groups-deselect").on("click", function (ev) {
    ev.preventDefault();

    $(".media-groups-modal").data("mode", "deselect");
    $(".media-groups-modal h4").text("Deselect Media Groups");
  });

  $(".media-group").on("click", function (ev) {
    ev.preventDefault();

    let $el = $(ev.target);
    let mode = $(".media-groups-modal").data("mode");
    let newValsSet = [];

    let groupId = $el.data("id");
    let currentVals = $(".chosen-select-medias").val();
    let ids = mediaGroups[groupId]["media_ids"];

    if (mode === "select") {
      newValsSet = new Set([...currentVals, ...ids]);
    } else {
      newValsSet = setDifference(currentVals, ids);
    }

    $(".chosen-select-medias")
      .val([...newValsSet])
      .trigger("chosen:updated");
    $(".media-groups-modal").modal("hide");
  });

  $("input[type=text]").keypress(function (e) {
    if (e.which == 13) {
      e.preventDefault();
    }
  });

  $("#form-submit").on("click", function (ev) {
    ev.preventDefault();

    const territoriesSelectInvalid =
      $(".tom-select-territories").val().length === 0;
    const mediasSelectInvalid = $(".chosen-select-medias").val().length === 0;

    if (territoriesSelectInvalid) {
      let territoriesLi = document.createElement("li");
      territoriesLi.innerHTML = "A Territories selection is required";
      document
        .querySelector("#validation-warning ul")
        .appendChild(territoriesLi);
    }

    if (mediasSelectInvalid) {
      let mediasLi = document.createElement("li");
      mediasLi.innerHTML = "A Media selection is required";
      document.querySelector("#validation-warning ul").appendChild(mediasLi);
    }

    const formValid = !territoriesSelectInvalid && !mediasSelectInvalid;

    if (formValid) {
      document.querySelector("#create-right").submit();
    } else {
      document.querySelector("#validation-warning").style.display = "block";
    }
  });
};

const editConnect = function () {
  new TomSelect("#licensor_id", {
    allowEmptyOption: true,
    plugins: ["remove_button", "clear_button"],
  });

  new TomSelect("#licensee_id", {
    allowEmptyOption: true,
    plugins: ["remove_button", "clear_button"],
  });

  const mediaGroupsData = document.querySelector(".media-groups-data");
  const mediaGroups = JSON.parse(mediaGroupsData.value);

  const setDifference = function (arrA, arrB) {
    let bSet = new Set(arrB);
    return new Set(arrA.filter((id) => !bSet.has(parseInt(id))));
  };

  let territoryGroupSelect = new TomSelect("#territory_group_id", {
    allowEmptyOption: true,
    plugins: ["remove_button", "clear_button"],
    onInitialize: function () {
      this.clear();
    },
  });

  let countrySelect = new TomSelect("#country_id", {
    allowEmptyOption: true,
    plugins: ["remove_button", "clear_button"],
    onInitialize: function () {
      this.clear();
    },
  });

  let languageSelect = new TomSelect("#language_id", {
    allowEmptyOption: true,
    plugins: ["remove_button", "clear_button"],
    onInitialize: function () {
      this.clear();
    },
  });

  let territoriesSelect = new TomSelect(".tom-select-territories", {
    allowEmptyOption: true,
    plugins: ["remove_button", "input_autogrow", "clear_button"],
    render: {
      no_results: null,
    },
  });

  let excludedTerritoriesSelect = new TomSelect(
    ".tom-select-excluded-territories",
    {
      allowEmptyOption: true,
      plugins: ["remove_button", "input_autogrow", "clear_button"],
      render: {
        no_results: null,
      },
    }
  );

  territoryGroupSelect.on("change", function (value) {
    if (value) {
      countrySelect.clear();
      languageSelect.clear();

      let territoryGroupOption = this.getOption(value);

      territoriesSelect.addOption({
        text: territoryGroupOption.textContent,
        value: value,
      });

      territoriesSelect.addItem(value);
    }
  });

  countrySelect.on("change", function (value) {
    if (value > 0 && languageSelect.getValue() > 0) {
      let languageId = languageSelect.getValue();
      let languageName = languageSelect.getOption(languageId).textContent;

      let countryId = this.getValue();
      let countryOption = this.getOption(countryId);
      let countryName = countryOption.textContent;

      territoriesSelect.addOption({
        text: `${countryName} (${languageName})`,
        value: `territory_${countryId}-${languageId}`,
      });

      territoriesSelect.addItem(`territory_${countryId}-${languageId}`);
    }
  });

  languageSelect.on("change", function (value) {
    if (value > 0 && countrySelect.getValue() > 0) {
      let languageId = value;
      let languageName = this.getOption(languageId).textContent;

      let countryId = countrySelect.getValue();
      let countryOption = countrySelect.getOption(countryId);
      let countryName = countryOption.textContent;

      territoriesSelect.addOption({
        text: `${countryName} (${languageName})`,
        value: `territory_${countryId}-${languageId}`,
      });

      territoriesSelect.addItem(`territory_${countryId}-${languageId}`);
    }
  });

  territoriesSelect.on("item_select", function (item) {
    let itemValue = item.getAttribute("data-value");

    excludedTerritoriesSelect.addOption({
      text: item.textContent.slice(0, -1),
      value: itemValue,
    });

    excludedTerritoriesSelect.addItem(itemValue);
    this.removeItem(itemValue);
    this.removeOption(itemValue);
  });

  excludedTerritoriesSelect.on("item_select", function (item) {
    let itemValue = item.getAttribute("data-value");

    territoriesSelect.addOption({
      text: item.textContent.slice(0, -1),
      value: itemValue,
    });

    territoriesSelect.addItem(itemValue);
    this.removeItem(itemValue);
    this.removeOption(itemValue);
  });

  $(".chosen-select-medias").chosen({
    no_results_text: "No options found",
    width: "500px",
  });

  $(".select-all").on("click", function (ev) {
    ev.preventDefault();

    const id = $(ev.currentTarget).data("id");
    $(`select#${id} option`).prop("selected", true);
    $(`select#${id}`).trigger("chosen:updated");
  });

  $(".deselect-all").on("click", function (ev) {
    ev.preventDefault();

    let id = $(ev.currentTarget).data("id");
    $(`select#${id} option`).prop("selected", false);
    $(`select#${id}`).trigger("chosen:updated");
  });

  $(".media-groups-select").on("click", function (ev) {
    ev.preventDefault();

    $(".media-groups-modal").data("mode", "select");
    $(".media-groups-modal h4").text("Select Media Groups");
  });

  $(".media-groups-deselect").on("click", function (ev) {
    ev.preventDefault();

    $(".media-groups-modal").data("mode", "deselect");
    $(".media-groups-modal h4").text("Deselect Media Groups");
  });

  $(".media-group").on("click", function (ev) {
    ev.preventDefault();

    let $el = $(ev.target);
    let mode = $(".media-groups-modal").data("mode");
    let newVals = [];

    let groupId = $el.data("id");
    let currentVals = $(".chosen-select-medias").val();
    let ids = mediaGroups[groupId]["media_ids"];

    if (mode === "select") {
      newValsSet = new Set([...currentVals, ...ids]);
    } else {
      newValsSet = setDifference(currentVals, ids);
    }

    $(".chosen-select-medias")
      .val([...newValsSet])
      .trigger("chosen:updated");
    $(".media-groups-modal").modal("hide");
  });

  $("input[type=text]").keypress(function (e) {
    if (e.which == 13) {
      e.preventDefault();
    }
  });

  $("#form-submit").on("click", function (ev) {
    ev.preventDefault();

    const territoriesSelectInvalid =
      $(".tom-select-territories").val().length === 0;
    const mediasSelectInvalid = $(".chosen-select-medias").val().length === 0;

    if (territoriesSelectInvalid) {
      let territoriesLi = document.createElement("li");
      territoriesLi.innerHTML = "A Territories selection is required";
      document
        .querySelector("#validation-warning ul")
        .appendChild(territoriesLi);
    }

    if (mediasSelectInvalid) {
      let mediasLi = document.createElement("li");
      mediasLi.innerHTML = "A Media selection is required";
      document.querySelector("#validation-warning ul").appendChild(mediasLi);
    }

    const formValid = !territoriesSelectInvalid && !mediasSelectInvalid;

    if (formValid) {
      document.querySelector("#edit-right").submit();
    } else {
      document.querySelector("#validation-warning").style.display = "block";
    }
  });
};

const showConnect = function () {
  const env = document.head.querySelector('meta[name="rails_env"]').content;
  const musicRightId = window.location.href.split("/")[5];

  new Uppy({
    id: "upload-documents",
    autoProceed: false,
    allowMultipleUploadBatches: true,
    debug: false,
    restrictions: {
      allowedFileTypes: [
        ".doc",
        ".docx",
        ".msg",
        ".pdf",
        ".rtf",
        ".txt",
        ".xls",
        ".xlsx",
        "image/*",
      ],
    },
    meta: {
      music_right_id: musicRightId,
    },
  })
    .use(Dashboard, {
      animateOpenClose: true,
      autoOpenFileEditor: false,
      closeAfterFinish: false,
      closeModalOnClickOutside: false,
      disableInformer: false,
      disableLocalFiles: false,
      disablePageScrollWhenModalOpen: true,
      disableStatusBar: false,
      disableThumbnailGenerator: false,
      disabled: false,
      fileManagerSelectionType: "files",
      height: 550,
      hideCancelButton: false,
      hidePauseResumeButton: false,
      hideProgressAfterFinish: false,
      hideRetryButton: false,
      id: "upload-documents-dashboard",
      inline: false,
      proudlyDisplayPoweredByUppy: false,
      showProgressDetails: true,
      showRemoveButtonAfterComplete: false,
      showSelectedFiles: true,
      singleFileFullScreen: true,
      target: "body",
      trigger: "#upload-trigger",
      width: 750,
    })
    .use(Transloadit, {
      assemblyOptions: {
        params: {
          auth: {
            key: "5ec4b898e212462ca6033f847b294f8e",
          },
          template_id: "471973167b91427fb749abfc4c6db63b",
          steps: {
            exported: {
              bucket: `avails-documents-${env}`,
            },
          },
        },
      },
    })
    .use(GoldenRetriever)
    .on("dashboard:modal-closed", () => {
      location.reload();
    })
    .on("upload-success", (file, _) => {
      fetch("/admin/music_right_documents/create_from_upload", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          music_right_id: musicRightId,
          file_name: file.name,
        }),
      });
    });
};

export default class extends Controller {
  connect() {
    const controllerAction = this.data.get("action");
    switch (controllerAction) {
      case "edit":
        editConnect();
        break;
      case "new":
        newConnect();
        break;
      case "show":
        showConnect();
        break;
    }
  }
}
