import { Controller } from '@hotwired/stimulus'
import Dashboard from '@uppy/dashboard'
import GoldenRetriever from '@uppy/golden-retriever'
import TomSelect from 'tom-select'
import Transloadit from '@uppy/transloadit'
import Uppy from '@uppy/core'

const viewConnect = function() {
  const selectSettings = {
    plugins: ['remove_button', 'input_autogrow', 'clear_button']
  };
  new TomSelect('#asset_type', selectSettings)
  new TomSelect('#title_ids', selectSettings)
  new TomSelect('#episode_ids', selectSettings)
}

const newConnect = function() {
  const env = document.head.querySelector('meta[name="rails_env"]').content

  new Uppy({
    id: "upload-assets",
    autoProceed: false,
    allowMultipleUploadBatches: true,
    debug: false,
    restrictions: {
      allowedFileTypes: [
        'image/*',
        'video/*',
        '.pdf',
      ]
    }
  })
    .use(Dashboard, {
      autoOpenFileEditor: false,
      disableInformer: false,
      disableLocalFiles: false,
      disableStatusBar: false,
      disableThumbnailGenerator: false,
      disabled: false,
      fileManagerSelectionType: 'files',
      height: 550,
      hideCancelButton: false,
      hidePauseResumeButton: false,
      hideProgressAfterFinish: false,
      hideRetryButton: false,
      id: 'upload-assets-dashboard',
      inline: true,
      proudlyDisplayPoweredByUppy: false,
      showProgressDetails: true,
      showRemoveButtonAfterComplete: false,
      showSelectedFiles: true,
      singleFileFullScreen: true,
      target: 'body',
      width: 750,
    })
    .use(Transloadit, {
      assemblyOptions: {
        params: {
          auth: {
            key: '5ec4b898e212462ca6033f847b294f8e'
          },
          template_id: '471973167b91427fb749abfc4c6db63b',
          steps: {
            "exported": {
              bucket: `avails-assets-${env}`
            }
          },
        },
      },
    })
    .use(GoldenRetriever)
}

export default class extends Controller {
  connect() {
    const controllerAction = this.data.get('action')
    switch (controllerAction) {
      case 'edit':
        viewConnect()
        break
      case 'new':
        newConnect()
        break
      case 'index':
        break
    }
  }
}
