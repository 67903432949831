$(function() {
  $('.select').click(function (ev) {
    ev.preventDefault();

    var id = '#' + $(this).closest('tr').attr('id');
    $(id + ' option').prop('selected', true);
    $(id + ' select').trigger('chosen:updated');
  });

  $('.deselect').click(function (ev) {
    ev.preventDefault();

    var id = '#' + $(this).closest('tr').attr('id');
    $(id + ' option').prop('selected', false)
    $(id + ' select').trigger('chosen:updated');
  });
});
