import { Controller } from '@hotwired/stimulus'
import TomSelect from 'tom-select'

const availabilitySearchBoxConnect = function() {
  const mediaGroupsData = document.querySelector('.media-groups-data')
  const mediaGroups = JSON.parse(mediaGroupsData.value)

  const setDifference = function(arrA, arrB) {
    let bSet = new Set(arrB)
    return new Set(arrA.filter(id => !bSet.has(parseInt(id))))
  }

  let territoryGroupSelect = new TomSelect('#territory_group_id', { 
    allowEmptyOption: true,
    plugins: ['remove_button', 'clear_button'],
    onInitialize: function() {
      this.clear()
    }
  })

  let countrySelect = new TomSelect('#country_id', {
    allowEmptyOption: true,
    plugins: ['remove_button', 'clear_button'],
    onInitialize: function() {
      this.clear()
    }
  })

  let languageSelect = new TomSelect('#language_id', {
    allowEmptyOption: true,
    plugins: ['remove_button', 'clear_button'],
    onInitialize: function() {
      this.clear()
    }
  })

  let territoriesSelect = new TomSelect('.tom-select-territories', {
    allowEmptyOption: true,
    plugins: ['remove_button', 'input_autogrow', 'clear_button'],
    render: {
      no_results: null,
    }
  })

  let excludedTerritoriesSelect = new TomSelect('.tom-select-excluded-territories', {
    allowEmptyOption: true,
    plugins: ['remove_button', 'input_autogrow', 'clear_button'],
    render: {
      no_results: null,
    }
  })

  territoryGroupSelect.on('change', function(value) {
    if (value) {
      countrySelect.clear()
      languageSelect.clear()

      let territoryGroupOption = this.getOption(value)

      territoriesSelect.addOption({
        text: territoryGroupOption.textContent,
        value: value
      })

      territoriesSelect.addItem(value)
    }
  })

  countrySelect.on('change', function(value) {
    if (value > 0 && languageSelect.getValue() > 0) {
      let languageId = languageSelect.getValue()
      let languageName = languageSelect.getOption(languageId).textContent

      let countryId = this.getValue()
      let countryOption = this.getOption(countryId)
      let countryName = countryOption.textContent

      territoriesSelect.addOption({
        text: `${countryName} (${languageName})`,
        value: `territory_${countryId}-${languageId}`
      })

      territoriesSelect.addItem(`territory_${countryId}-${languageId}`)
    }
  })

  languageSelect.on('change', function(value) {
    if (value > 0 && countrySelect.getValue() > 0) {
      let languageId = value
      let languageName = this.getOption(languageId).textContent

      let countryId = countrySelect.getValue()
      let countryOption = countrySelect.getOption(countryId)
      let countryName = countryOption.textContent

      territoriesSelect.addOption({
        text: `${countryName} (${languageName})`,
        value: `territory_${countryId}-${languageId}`
      })

      territoriesSelect.addItem(`territory_${countryId}-${languageId}`)
    }
  })

  territoriesSelect.on('item_select', function(item) {
    let itemValue = item.getAttribute('data-value')
  
    excludedTerritoriesSelect.addOption({
      text: item.textContent.slice(0, -1),
      value: itemValue
    })

    excludedTerritoriesSelect.addItem(itemValue)
    this.removeItem(itemValue)
    this.removeOption(itemValue)
  })

  excludedTerritoriesSelect.on('item_select', function(item) {
    let itemValue = item.getAttribute('data-value')

    territoriesSelect.addOption({
      text: item.textContent.slice(0, -1),
      value: itemValue
    })

    territoriesSelect.addItem(itemValue)
    this.removeItem(itemValue)
    this.removeOption(itemValue)
  })

  $('.chosen-select-title-type').chosen({ no_results_text: 'No options found', width: '100%' });
  $('.chosen-select-sales-licensees').chosen({ no_results_text: 'No options found', width: '100%' });
  $('.chosen-select-sales-territories').chosen({ no_results_text: 'No options found', width: '100%' });
  $('.chosen-select-sales-languages').chosen({ no_results_text: 'No options found', width: '100%' });
  $('.chosen-select-sales-medias').chosen({ no_results_text: 'No options found', width: '100%' });
  $('.chosen-select-exclusivity-type').chosen({ no_results_text: 'No options found', width: '100%' });
  $('.chosen-select-genres').chosen({ no_results_text: 'No options found', width: '100%' });
  $('.chosen-select-sound-languages').chosen({ no_results_text: 'No options found', width: '100%' });
  $('.chosen-select-sound-formats').chosen({ no_results_text: 'No options found', width: '100%' });

  $('.select-all').on('click', function (ev) {
    ev.preventDefault()

    const id = $(ev.currentTarget).data('id')
    $(`select#${id} option`).not('[value=""]').prop('selected', true)
    $(`select#${id}`).trigger('chosen:updated')
  })

  $('.deselect-all').on('click', function (ev) {
    ev.preventDefault()

    let id = $(ev.currentTarget).data('id')
    $(`select#${id} option`).prop('selected', false)
    $(`select#${id}`).trigger('chosen:updated')
  })

  $('.territory-groups-select').on('click', function(ev) {
    ev.preventDefault()

    $('.territory-groups-modal').data('mode', 'select')
    $('.territory-groups-modal h4').text('Select Territory Groups')
  })

  $('.territory-groups-deselect').on('click', function(ev) {
    ev.preventDefault()

    $('.territory-groups-modal').data('mode', 'deselect')
    $('.territory-groups-modal h4').text('Deselect Territory Groups')
  })

  $('.media-groups-select').on('click', function(ev) {
    ev.preventDefault()

    $('.media-groups-modal').data('mode', 'select')
    $('.media-groups-modal h4').text('Select Media Groups')
  })

  $(".media-groups-deselect").on('click', function(ev) {
    ev.preventDefault()

    $('.media-groups-modal').data('mode', 'deselect')
    $('.media-groups-modal h4').text('Deselect Media Groups')
  })

  $('.media-group').on('click', function(ev) {
    ev.preventDefault()

    let $el = $(ev.target)
    let mode = $('.media-groups-modal').data('mode')
    let newValsSet = []

    let groupId = $el.data('id')
    let currentVals = $('.chosen-select-sales-medias').val()
    let ids = mediaGroups[groupId]['media_ids']

    if (mode === 'select') {
      newValsSet = new Set([...currentVals, ...ids])
    } else {
      newValsSet = setDifference(currentVals, ids)
    }

    $('.chosen-select-sales-medias').val([...newValsSet]).trigger('chosen:updated')
    $('.media-groups-modal').modal('hide')
  })
}

const availabilityConnect = function() { 
  const mediaGroupsData = document.querySelector('.media-groups-data')
  const mediaGroups = JSON.parse(mediaGroupsData.value)

  const setDifference = function(arrA, arrB) {
    let bSet = new Set(arrB)
    return new Set(arrA.filter(id => !bSet.has(parseInt(id))))
  }

  let territoryGroupSelect = new TomSelect('#territory_group_id', { 
    allowEmptyOption: true,
    plugins: ['remove_button', 'clear_button'],
    onInitialize: function() {
      this.clear()
    }
  })

  let countrySelect = new TomSelect('#country_id', {
    allowEmptyOption: true,
    plugins: ['remove_button', 'clear_button'],
    onInitialize: function() {
      this.clear()
    }
  })

  let languageSelect = new TomSelect('#language_id', {
    allowEmptyOption: true,
    plugins: ['remove_button', 'clear_button'],
    onInitialize: function() {
      this.clear()
    }
  })

  let territoriesSelect = new TomSelect('.tom-select-territories', {
    allowEmptyOption: true,
    plugins: ['remove_button', 'input_autogrow', 'clear_button'],
    render: {
      no_results: null,
    }
  })

  let excludedTerritoriesSelect = new TomSelect('.tom-select-excluded-territories', {
    allowEmptyOption: true,
    plugins: ['remove_button', 'input_autogrow', 'clear_button'],
    render: {
      no_results: null,
    }
  })

  territoryGroupSelect.on('change', function(value) {
    if (value) {
      countrySelect.clear()
      languageSelect.clear()

      let territoryGroupOption = this.getOption(value)

      territoriesSelect.addOption({
        text: territoryGroupOption.textContent,
        value: value
      })

      territoriesSelect.addItem(value)
    }
  })

  countrySelect.on('change', function(value) {
    if (value > 0 && languageSelect.getValue() > 0) {
      let languageId = languageSelect.getValue()
      let languageName = languageSelect.getOption(languageId).textContent

      let countryId = this.getValue()
      let countryOption = this.getOption(countryId)
      let countryName = countryOption.textContent

      territoriesSelect.addOption({
        text: `${countryName} (${languageName})`,
        value: `territory_${countryId}-${languageId}`
      })

      territoriesSelect.addItem(`territory_${countryId}-${languageId}`)
    }
  })

  languageSelect.on('change', function(value) {
    if (value > 0 && countrySelect.getValue() > 0) {
      let languageId = value
      let languageName = this.getOption(languageId).textContent

      let countryId = countrySelect.getValue()
      let countryOption = countrySelect.getOption(countryId)
      let countryName = countryOption.textContent

      territoriesSelect.addOption({
        text: `${countryName} (${languageName})`,
        value: `territory_${countryId}-${languageId}`
      })

      territoriesSelect.addItem(`territory_${countryId}-${languageId}`)
    }
  })

  territoriesSelect.on('item_select', function(item) {
    let itemValue = item.getAttribute('data-value')
  
    excludedTerritoriesSelect.addOption({
      text: item.textContent.slice(0, -1),
      value: itemValue
    })

    excludedTerritoriesSelect.addItem(itemValue)
    this.removeItem(itemValue)
    this.removeOption(itemValue)
  })

  excludedTerritoriesSelect.on('item_select', function(item) {
    let itemValue = item.getAttribute('data-value')

    territoriesSelect.addOption({
      text: item.textContent.slice(0, -1),
      value: itemValue
    })

    territoriesSelect.addItem(itemValue)
    this.removeItem(itemValue)
    this.removeOption(itemValue)
  })

  $('.chosen-select-title-type').chosen({ no_results_text: 'No options found', width: '100%' });
  $('.chosen-select-genres').chosen({ no_results_text: 'No options found', width: '100%' });
  $('.chosen-select-sound-languages').chosen({ no_results_text: 'No options found', width: '100%' });
  $('.chosen-select-sound-formats').chosen({ no_results_text: 'No options found', width: '100%' });
  $('.chosen-select-origins').chosen({ no_results_text: 'No options found', width: '100%' });
  $('.chosen-select-mpaa-ratings').chosen({ no_results_text: 'No options found', width: '100%' });
  $('.chosen-select-tv-ratings').chosen({ no_results_text: 'No options found', width: '100%' });
  $('.chosen-select-sales-licensees').chosen({ no_results_text: 'No options found', width: '100%' });
  $('.chosen-select-sales-territories').chosen({ no_results_text: 'No options found', width: '100%' });
  $('.chosen-select-sales-languages').chosen({ no_results_text: 'No options found', width: '100%' });
  $('.chosen-select-sales-medias').chosen({ no_results_text: 'No options found', width: '100%' });
  $('.chosen-select-exclusivity-type').chosen({ no_results_text: 'No options found', width: '100%' });

  $('.select-all').on('click', function (ev) {
    ev.preventDefault()

    const id = $(ev.currentTarget).data('id')
    $(`select#${id} option`).not('[value=""]').prop('selected', true)
    $(`select#${id}`).trigger('chosen:updated')
  })

  $('.deselect-all').on('click', function (ev) {
    ev.preventDefault()

    let id = $(ev.currentTarget).data('id')
    $(`select#${id} option`).prop('selected', false)
    $(`select#${id}`).trigger('chosen:updated')
  })

  $('.media-groups-select').on('click', function(ev) {
    ev.preventDefault()

    $('.media-groups-modal').data('mode', 'select')
    $('.media-groups-modal h4').text('Select Media Groups')
  })

  $(".media-groups-deselect").on('click', function(ev) {
    ev.preventDefault()

    $('.media-groups-modal').data('mode', 'deselect')
    $('.media-groups-modal h4').text('Deselect Media Groups')
  })

  $('.media-group').on('click', function(ev) {
    ev.preventDefault()

    let $el = $(ev.target)
    let mode = $('.media-groups-modal').data('mode')
    let newValsSet = []

    let groupId = $el.data('id')
    let currentVals = $('.chosen-select-sales-medias').val()
    let ids = mediaGroups[groupId]['media_ids']

    if (mode === 'select') {
      newValsSet = new Set([...currentVals, ...ids])
    } else {
      newValsSet = setDifference(currentVals, ids)
    }

    $('.chosen-select-sales-medias').val([...newValsSet]).trigger('chosen:updated')
    $('.media-groups-modal').modal('hide')
  })
}

const viewConnect = function() {  
  $('.chosen-select-title-type').chosen({ no_results_text: 'No options found', width: '500px' });
  $('.chosen-select-genres').chosen({ no_results_text: 'No options found', width: '500px' });
  $('.chosen-select-sound-languages').chosen({ no_results_text: 'No options found', width: '500px' });
  $('.chosen-select-sound-formats').chosen({ no_results_text: 'No options found', width: '500px' });
  $('.chosen-select-origins').chosen({ no_results_text: 'No options found', width: '500px' });
  $('.chosen-select-actors').chosen({ no_results_text: 'No options found', width: '500px' });
  $('.chosen-select-writers').chosen({ no_results_text: 'No options found', width: '500px' });
  $('.chosen-select-directors').chosen({ no_results_text: 'No options found', width: '500px' });
  $('.chosen-select-mpaa-ratings').chosen({ no_results_text: 'No options found', width: '500px' });
  $('.chosen-select-tv-ratings').chosen({ no_results_text: 'No options found', width: '500px' });
  $('.chosen-select-sales-licensees').chosen({ no_results_text: 'No options found', width: '500px' });
  $('.chosen-select-sales-territories').chosen({ no_results_text: 'No options found', width: '500px' });
  $('.chosen-select-sales-languages').chosen({ no_results_text: 'No options found', width: '500px' });
  $('.chosen-select-sales-medias').chosen({ no_results_text: 'No options found', width: '500px' });
  $('.chosen-select-right-of-first-refusal').chosen({ no_results_text: 'No options found', width: '500px' });
  $('.chosen-select-exclusivity-type').chosen({ no_results_text: 'No options found', width: '500px' });
  $('.chosen-select-underlying-rights-licensors').chosen({ no_results_text: 'No options found', width: '500px' });
  $('.chosen-select-underlying-rights-territories').chosen({ no_results_text: 'No options found', width: '500px' });
  $('.chosen-select-underlying-rights-languages').chosen({ no_results_text: 'No options found', width: '500px' });
  $('.chosen-select-underlying-rights-medias').chosen({ no_results_text: 'No options found', width: '500px' });
}

const showConnect = function() {
  const selectSettings = {
    allowEmptyOption: true,
    plugins: ['remove_button', 'input_autogrow', 'clear_button']
  }

  let titleSelect = new TomSelect('#title_id', selectSettings)

  titleSelect.on('change', function(value) {
    if (value) {
      const baseUrl = window.location.href.split('?')
      window.open(`${baseUrl[0]}?title_id=${value}`).focus()
    }
  })
}

export default class extends Controller {
  connect() {
    const controllerAction = this.data.get('action')
    switch (controllerAction) {
      case 'availability':
        availabilityConnect()
        break
      case 'availability-search-box':
        availabilitySearchBoxConnect()
        break
      case 'episodes':
        viewConnect()
        break
      case 'titles':
        viewConnect()
        break
      case 'new':
        viewConnect()
        break
      case 'show':
        showConnect()
        break
    }
  }
}
