// Entry point for the build script in your package.json
require('@rails/ujs').start()
require('@rails/activestorage').start()

import './packages/jquery.js' // position for hoisting
import './controllers'
import * as bootstrap from 'bootstrap'

import 'jquery-validation/dist/jquery.validate.js'
import 'jquery.dirty/dist/jquery.dirty.js'
import 'chosen-js/chosen.jquery.js'

import './customizations/validation-extended.js'
import './customizations/chosen-extended.js'
import './customizations/truncate.js'
