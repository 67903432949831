import { Controller } from '@hotwired/stimulus'
import Sortable from 'sortablejs'

export default class extends Controller {
  connect() {
    $('form#pick_list').dirty({
      preventLeaving: true,
      leavingMessage: 'Changes you have made to the Pick List will not be saved.',
      onDirty: function() {
        console.log('just got dirty');
      }
    });

    $('.chosen-select-title').chosen({ no_results_text: 'No title found', width: '30%' });
    $('.chosen-select-episode').chosen({ no_results_text: 'No episode found', width: '30%' });

    $('.chosen-select-title').on('change', function() {
      const selectedValue = $('.chosen-select-title option:selected').val();

      if (selectedValue !== '') {
        $('.btn-add-title:hidden').show();
      } else {
        $('.btn-add-title:visible').hide();
      }
    });

    $('.chosen-select-episode').on('change', function() {
      const selectedValue = $('.chosen-select-episode option:selected').val();

      if (selectedValue !== '') {
        $('.btn-add-episode:hidden').show();
      } else {
        $('.btn-add-episode:visible').hide();
      }
    });

    const showMode = function() {
      // Show presentation fields
      $('.show-group:hidden').show();

      // Hide form inputs
      $('.edit-group:visible').hide();

      // Show form buttons
      $('.btn-edit-list:hidden').show();
      $('.btn-admin-view:hidden').show();
      $('.btn-export-xlsx:hidden').show();

      // Hide form buttons
      $('.btn-cancel-changes:visible').hide();
      $('.btn-update-changes:visible').hide();
    };

    const editMode = function() {
      // Hide presentation fields
      $('.show-group:visible').hide();

      // Reveal form inputs
      $('.edit-group:hidden').show();

      // Hide form buttons
      $('.btn-edit-list:visible').hide();
      $('.btn-admin-view:visible').hide();
      $('.btn-export-xlsx:visible').hide();
      
      // Reveal form buttons
      $('.btn-cancel-changes:hidden').show();
      $('.btn-update-changes:hidden').show();
    };

    const updateListItemsChangedStatus = function() {
      $('input[name="list_items_changed"]').val(true);
    }

    const updateListItemPositions = function() {
      const listGroupItems = $('.list-group-item');
      const positionValues = $.map(listGroupItems, function(item, index) {
        return {
          id: $(item).data('id'),
          status: $(item).data('status'),
          listable_type: $(item).data('listable-type'),
          listable_id: $(item).data('listable-id'),
          position: index + 1
        };
      });

      $('input[name="item_positions"]').val(JSON.stringify(positionValues));

      listGroupItems.each(function(index) {
        $(this).find('.position-number').text(`${index + 1}.`);
      });
    };

    const addPickListItem = function(listableId, listableType, listableName) {
      $('#sortable-list').prepend(
        `<div
          class="list-group-item ps-1 pt-2 pe-2 pb-2"
          data-id="0"
          data-status="added"
          data-listable-type="${listableType}"
          data-listable-id="${listableId}"
        >
          <button type="button" class="btn btn-default btn-sm">
            <i class="bi bi-grip-horizontal"></i>
          </button>
          <span class="position-number"></span>&nbsp;${listableName}
          <button type="button" class="btn btn-default btn-sm btn-remove-item float-end">
            <i class="bi bi-x-circle"></i>
          </button>
        </div>`
      )
    }

    const removePickListItem = function(id, listableId, listableType) {
      $(`.list-group-item[data-id=${id}]`).remove();

      $('#sortable-list').append(
        `<div
          class="list-group-item"
          data-id="${id}"
          data-status="removed"
          data-type="${listableType}"
          data-listable-id="${listableId}"
          style="display: none;"
        >
        </div>`
      )
    }

    const el = document.getElementById('sortable-list');
    
    const sortable = Sortable.create(el, {
      dataIdAttr: 'data-id',
      chosenClass: 'list-group-item-primary',
      dragClass: 'list-group-item-info',
      onEnd: function (evt) {
        updateListItemsChangedStatus();
        updateListItemPositions();
        editMode();
        $('form#pick_list').dirty('setAsDirty');
      },
    });

    $('.btn-add-title').on('click', function(ev) {
      ev.preventDefault();

      const id = $('.chosen-select-title').val();
      const name = $('.chosen-select-title option:selected').text();

      addPickListItem(id, 'Title', name);
      updateListItemPositions();
      updateListItemsChangedStatus();
      editMode();

      $('.chosen-select-title').val('').trigger('chosen:updated');
      $(this).hide();
    });

    $('.btn-add-episode').on('click', function(ev) {
      ev.preventDefault();

      const id = $('.chosen-select-episode').val();
      const name = $('.chosen-select-episode option:selected').text();

      addPickListItem(id, 'Episode', name);
      updateListItemPositions();
      updateListItemsChangedStatus();
      editMode();

      $('.chosen-select-episode').val('').trigger('chosen:updated');
      $(this).hide();
    });

    $('.btn-remove-item').on('click', function(ev) {
      ev.preventDefault();

      const $listItem = $(this).closest('.list-group-item');

      const id = $listItem.data('id');
      const listableId = $listItem.data('listable-id');
      const listableType = $listItem.data('listable-type');
      
      removePickListItem(id, listableId, listableType);
      updateListItemPositions();
      updateListItemsChangedStatus();
      editMode();
    })

    $('.btn-edit-list').on('click', function(ev) {
      ev.preventDefault();

      editMode();
    });

    $('.btn-admin-view').on('click', function(ev) {
      ev.preventDefault();

      window.location.replace('<%= admin_pick_list_path(@pick_list) %>');
    });
  }
}
