import { Controller } from "@hotwired/stimulus";
import TomSelect from "tom-select";

// Connects to data-controller="title-territory-availability"
export default class extends Controller {
  connect() {
    const selectSettings = {
      plugins: ["remove_button", "input_autogrow", "clear_button"],
    };

    new TomSelect("#title_id", selectSettings);
    new TomSelect("#country_id", selectSettings);
  }
}
