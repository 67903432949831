import { Controller } from '@hotwired/stimulus'

const viewConnect = function() {

}

export default class extends Controller {
  connect() {
    const controllerAction = this.data.get('action')
    switch (controllerAction) {
      case 'edit':
        viewConnect()
        break
      case 'new':
        viewConnect()
        break
      case 'index':
        break
    }
  }
}
