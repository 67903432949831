import { Controller } from '@hotwired/stimulus'
import Dashboard from '@uppy/dashboard'
import GoldenRetriever from '@uppy/golden-retriever'
import TomSelect from 'tom-select'
import Transloadit from '@uppy/transloadit'
import Uppy from '@uppy/core'

const viewConnect = function() {
  const selectSettings = {
    allowEmptyOption: true,
    plugins: ['remove_button', 'input_autogrow', 'clear_button']
  }
  new TomSelect('#project_id', selectSettings)
  new TomSelect('#title_id', selectSettings)
  new TomSelect('#licensor_id', selectSettings)
}

const showConnect = function() {
  const env = document.head.querySelector('meta[name="rails_env"]').content
  const underlyingRightId = window.location.href.split('/')[5]

  new Uppy({
    id: "upload-documents",
    autoProceed: false,
    allowMultipleUploadBatches: true,
    debug: false,
    restrictions: {
      allowedFileTypes: [
        '.doc',
        '.docx',
        '.msg',
        '.pdf',
        '.rtf',
        '.txt',
        '.xls',
        '.xlsx',
        'image/*',
      ]
    },
    meta: {
      underlying_right_id: underlyingRightId,
    }
  })
    .use(Dashboard, {
      animateOpenClose: true,
      autoOpenFileEditor: false,
      closeAfterFinish: false,
      closeModalOnClickOutside: false,
      disableInformer: false,
      disableLocalFiles: false,
      disablePageScrollWhenModalOpen: true,
      disableStatusBar: false,
      disableThumbnailGenerator: false,
      disabled: false,
      fileManagerSelectionType: 'files',
      height: 550,
      hideCancelButton: false,
      hidePauseResumeButton: false,
      hideProgressAfterFinish: false,
      hideRetryButton: false,
      id: 'upload-documents-dashboard',
      inline: false,
      proudlyDisplayPoweredByUppy: false,
      showProgressDetails: true,
      showRemoveButtonAfterComplete: false,
      showSelectedFiles: true,
      singleFileFullScreen: true,
      target: 'body',
      trigger: '#upload-trigger',
      width: 750,
    })
    .use(Transloadit, {
      assemblyOptions: {
        params: {
          auth: {
            key: '5ec4b898e212462ca6033f847b294f8e'
          },
          template_id: '471973167b91427fb749abfc4c6db63b',
          steps: {
            'exported': {
              bucket: `avails-documents-${env}`
            }
          },
        },
      },
    })
    .use(GoldenRetriever)
    .on('dashboard:modal-closed', () => {
      location.reload()
    })
    .on('upload-success', (file, _) => {
      fetch('/admin/underlying_right_documents/create_from_upload', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          underlying_right_id: underlyingRightId,
          file_name: file.name,
        })
      })
    })
}

export default class extends Controller {
  connect() {
    const controllerAction = this.data.get('action')
    switch (controllerAction) {
      case 'show':
        showConnect()
        break
      case 'edit':
        viewConnect()
        break
      case 'new':
        viewConnect()
        break
      case 'index':
        break
    }
  }
}
