import { Controller } from '@hotwired/stimulus'
import TomSelect from 'tom-select'

function fullDescription({ first_name, middle_name = '', last_name = '', role, title_name = '', character_name = '' }) {
  // Initialize the full name
  let full_name = first_name
  if (middle_name) full_name += ` ${middle_name}`
  if (last_name) full_name += ` ${last_name}`

  // Initialize the role description
  let role_description = `${role.charAt(0).toUpperCase() + role.slice(1)}`

  // Format the full description based on the role
  let full_description;
  switch (role.toLowerCase()) {
    case 'producer':
    case 'director':
    case 'writer':
      full_description = title_name ? `${full_name} (${title_name})` : `${full_name} (${role_description})`
      break
    case 'actor':
      full_description = character_name ? `${full_name} (${character_name})` : `${full_name} (${role_description})`
      break
    default:
      full_description = `${full_name} (${role_description})`
  }

  return full_description.trim()
}

const showConnect = function() {
  document.addEventListener('DOMContentLoaded', (event) => {
    const copyButton = document.getElementById('copy-link-button')
    const textToCopy = document.getElementById('copy-link-text').innerText

    copyButton.addEventListener('click', () => {
      navigator.clipboard.writeText(textToCopy).then(() => {
      }).catch(err => {
        console.error('Failed to copy text: ', err)
      })
    })
  })

  document.querySelectorAll('.copy-button').forEach(button => {
    button.addEventListener('click', (e) => {
      const field = e.currentTarget.getAttribute('data-field')
      let value = e.currentTarget.getAttribute('data-value')
      const titleId = e.currentTarget.closest('table').getAttribute('data-title-id')

      // Check if the value is a JSON string (array or object)
      try {
        value = JSON.parse(value)
      } catch (error) {
        // do nothing
      }

      fetch(`/admin/titles/${titleId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
        },
        body: JSON.stringify({ [field]: value })
      })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          location.reload()
        } else {
          console.log('Failed to copy data.')
        }
      })
      .catch(error => {
        console.error('Error:', error)
      })
    })
  })
}

const viewConnect = function() {
  const selectSettings = {
    allowEmptyOption: true,
    plugins: ['remove_button', 'input_autogrow', 'clear_button']
  }
  new TomSelect('#title_id', selectSettings)
  new TomSelect('#mpaa_rating', selectSettings)
}

const editConnect = function() {
  const selectSettings = {
    allowEmptyOption: true,
    plugins: ['remove_button', 'input_autogrow', 'clear_button']
  }
  // new TomSelect('#account_type', selectSettings)
  new TomSelect('#aspect_ratios', selectSettings)
  new TomSelect('#caption_languages', selectSettings)
  new TomSelect('#country_id', selectSettings)
  new TomSelect('#genres', selectSettings)
  new TomSelect('#mpaa_rating', selectSettings)
  new TomSelect('#origins', selectSettings)
  new TomSelect('#sound_formats', selectSettings)
  new TomSelect('#sound_languages', selectSettings)
  new TomSelect('#title_id', selectSettings)
  new TomSelect('#trailer_sound_formats', selectSettings)
  new TomSelect('#tv_rating', selectSettings)
  new TomSelect('#video_formats', selectSettings)
  new TomSelect('#trailer_video_formats', selectSettings)
  // new TomSelect('#url_type', selectSettings)
}

const publicEditConnect = function() {
  const selectSettings = {
    allowEmptyOption: true,
    plugins: ['remove_button', 'input_autogrow', 'clear_button']
  }
  new TomSelect('#social_media_account_type', selectSettings)
  new TomSelect('#aspect_ratios', selectSettings)
  new TomSelect('#caption_languages', selectSettings)
  new TomSelect('#country_id', selectSettings)
  new TomSelect('#genres', selectSettings)
  new TomSelect('#mpaa_rating', selectSettings)
  new TomSelect('#origins', selectSettings)
  new TomSelect('#sound_formats', selectSettings)
  new TomSelect('#sound_languages', selectSettings)
  new TomSelect('#trailer_sound_formats', selectSettings)
  new TomSelect('#tv_rating', selectSettings)
  new TomSelect('#video_formats', selectSettings)
  new TomSelect('#trailer_video_formats', selectSettings)
  new TomSelect('#url_type', selectSettings)

  const removeButtons = document.getElementsByClassName('remove-button')
  Array.from(removeButtons).forEach(button => {
    button.addEventListener('click', event => {
      event.preventDefault()
      const row = event.target.closest('.row')
      row.remove()
    })
  })

  document.getElementById('addLocalizedNameButton').addEventListener('click', function(event) {
    event.preventDefault()

    const localizedNameField = document.getElementById('localized_name')
    const countryIdField = document.getElementById('country_id')
    
    const localizedName = localizedNameField.value.trim()
    const selectedOption = countryIdField.selectedOptions[0]
    const countryId = selectedOption.value
    const countryName = selectedOption.textContent

    if (localizedName && countryId) {
      const entriesContainer = document.getElementById('entriesContainer')

      const row = document.createElement('div')
      row.className = 'row mb-2'

      // Create a hidden input to store the name value
      const hiddenNameInput = document.createElement('input')
      hiddenNameInput.type = 'hidden'
      hiddenNameInput.name = 'localized_names[]'
      hiddenNameInput.value = localizedName

      // Create a hidden input to store the country ID value
      const hiddenCountryIdInput = document.createElement('input')
      hiddenCountryIdInput.type = 'hidden'
      hiddenCountryIdInput.name = 'country_ids[]'
      hiddenCountryIdInput.value = countryId
      
      // Create a visible text element to show name
      const nameDisplayText = document.createElement('div')
      nameDisplayText.className = 'ms-2'
      nameDisplayText.textContent = localizedName

      // Create a visible text element to show name
      const countryDisplayText = document.createElement('div')
      countryDisplayText.className = 'ms-4'
      countryDisplayText.textContent = countryName

      const nameCol = document.createElement('div')
      nameCol.className = 'col-4'
      nameCol.appendChild(nameDisplayText)

      const countryCol = document.createElement('div')
      countryCol.className = 'col-4'
      countryCol.appendChild(countryDisplayText)

      const removeCol = document.createElement('div')
      removeCol.className = 'col-4'

      const removeIcon = document.createElement('i')
      removeIcon.className = 'bi bi-dash-circle'
      
      const removeButton = document.createElement('button')
      removeButton.type = 'button'
      removeButton.className = 'btn btn-danger'
      removeButton.appendChild(removeIcon)
      removeButton.appendChild(document.createTextNode(' Remove'))

      removeButton.addEventListener('click', function() {
        entriesContainer.removeChild(row)
      })

      removeCol.appendChild(removeButton)

      row.appendChild(hiddenNameInput)
      row.appendChild(hiddenCountryIdInput)
      row.appendChild(nameCol)
      row.appendChild(countryCol)
      row.appendChild(removeCol)

      entriesContainer.appendChild(row)
      
      // Reset the fields
      localizedNameField.value = ''
      countryIdField.value = ''
    } else {
      console.error('Error:', error)
    }
  })

  document.getElementById('addLinkButton').addEventListener('click', function(event) {
    event.preventDefault()

    const urlField = document.getElementById('url')
    const urlTypeField = document.getElementById('url_type')
    const secureCodeField = document.getElementById('secure_code')
    const notesField = document.getElementById('notes')

    const url = urlField.value.trim()
    const urlType = urlTypeField.value.trim()
    const secureCode = secureCodeField.value.trim()
    const notes = notesField.value.trim()

    if (url) {
      const entriesContainer = document.getElementById('manualLinksEntriesContainer')

      const row = document.createElement('div')
      row.className = 'row mb-2'

      // Create a hidden input to store the first name value
      const hiddenUrlInput = document.createElement('input')
      hiddenUrlInput.type = 'hidden'
      hiddenUrlInput.name = 'urls[]'
      hiddenUrlInput.value = url === null ? 'empty' : url

      // Create a hidden input to store the middle name value
      const hiddenUrlTypeInput = document.createElement('input')
      hiddenUrlTypeInput.type = 'hidden'
      hiddenUrlTypeInput.name = 'url_types[]'
      hiddenUrlTypeInput.value = (urlType === null || urlType.trim() === '') ? 'other' : urlType
      
      // Create a hidden input to store the last name value
      const hiddenSecureCodeInput = document.createElement('input')
      hiddenSecureCodeInput.type = 'hidden'
      hiddenSecureCodeInput.name = 'secure_codes[]'
      hiddenSecureCodeInput.value = secureCode === null ? ' ' : secureCode

      // Create a hidden input to store the title name value
      const hiddenNotesInput = document.createElement('input')
      hiddenNotesInput.type = 'hidden'
      hiddenNotesInput.name = 'link_notes[]'
      hiddenNotesInput.value = notes === null ? ' ' : notes

      // Create a visible text element to show name
      const linkDisplayText = document.createElement('div')
      linkDisplayText.className = 'ms-2'
      linkDisplayText.textContent = `(${hiddenUrlTypeInput.value}) ${url}`

      const linkCol = document.createElement('div')
      linkCol.className = 'col-4'
      linkCol.appendChild(linkDisplayText)

      const removeCol = document.createElement('div')
      removeCol.className = 'col-4'

      const removeIcon = document.createElement('i')
      removeIcon.className = 'bi bi-dash-circle'
      
      const removeButton = document.createElement('button')
      removeButton.type = 'button'
      removeButton.className = 'btn btn-danger'
      removeButton.appendChild(removeIcon)
      removeButton.appendChild(document.createTextNode(' Remove'))

      removeButton.addEventListener('click', function() {
        entriesContainer.removeChild(row)
      })

      removeCol.appendChild(removeButton)

      row.appendChild(hiddenUrlInput)
      row.appendChild(hiddenUrlTypeInput)
      row.appendChild(hiddenSecureCodeInput)
      row.appendChild(hiddenNotesInput)
      row.appendChild(linkCol)
      row.appendChild(removeCol)

      entriesContainer.appendChild(row)
      
      // Reset the fields
      urlField.value = ''
      urlTypeField.value = ''
      secureCodeField.value = ''
      notesField.value = ''
    }
  })

  document.getElementById('addSocialMediaAccountButton').addEventListener('click', function(event) {
    event.preventDefault()

    const socialMediaAccountUrlField = document.getElementById('social_media_account_url')
    const socialMediaAccountTypeField = document.getElementById('social_media_account_type')
    const socialMediaAccountHandleField = document.getElementById('social_media_account_handle')

    const socialMediaAccountUrl = socialMediaAccountUrlField.value.trim()
    const socialMediaAccountType = socialMediaAccountTypeField.value.trim()
    const socialMediaAccountHandle = socialMediaAccountHandleField.value.trim()

    if (url) {
      const entriesContainer = document.getElementById('social_media_accounts_entries_container')

      const row = document.createElement('div')
      row.className = 'row mb-2'

      // Create a hidden input to store the first name value
      const hiddenSocialMediaAccountUrlInput = document.createElement('input')
      hiddenSocialMediaAccountUrlInput.type = 'hidden'
      hiddenSocialMediaAccountUrlInput.name = 'social_media_urls[]'
      hiddenSocialMediaAccountUrlInput.value = socialMediaAccountUrl === null ? 'empty' : socialMediaAccountUrl

      // Create a hidden input to store the middle name value
      const hiddenSocialMediaAccountTypeInput = document.createElement('input')
      hiddenSocialMediaAccountTypeInput.type = 'hidden'
      hiddenSocialMediaAccountTypeInput.name = 'social_media_account_types[]'
      hiddenSocialMediaAccountTypeInput.value = (socialMediaAccountType === null || socialMediaAccountType.trim() === '') ? 'other' : socialMediaAccountType
      
      // Create a hidden input to store the last name value
      const hiddenSocialMediaAccountHandleInput = document.createElement('input')
      hiddenSocialMediaAccountHandleInput.type = 'hidden'
      hiddenSocialMediaAccountHandleInput.name = 'social_media_handles[]'
      hiddenSocialMediaAccountHandleInput.value = socialMediaAccountHandle === null ? ' ' : socialMediaAccountHandle

      // Create a visible text element to show name
      const socialMediaAccountDisplayText = document.createElement('div')
      socialMediaAccountDisplayText.className = 'ms-2'
      socialMediaAccountDisplayText.textContent = `(${hiddenSocialMediaAccountTypeInput.value}) ${hiddenSocialMediaAccountHandleInput}`

      const linkCol = document.createElement('div')
      linkCol.className = 'col-4'
      linkCol.appendChild(socialMediaAccountDisplayText)

      const removeCol = document.createElement('div')
      removeCol.className = 'col-4'

      const removeIcon = document.createElement('i')
      removeIcon.className = 'bi bi-dash-circle'
      
      const removeButton = document.createElement('button')
      removeButton.type = 'button'
      removeButton.className = 'btn btn-danger'
      removeButton.appendChild(removeIcon)
      removeButton.appendChild(document.createTextNode(' Remove'))

      removeButton.addEventListener('click', function() {
        entriesContainer.removeChild(row)
      })

      removeCol.appendChild(removeButton)

      row.appendChild(hiddenSocialMediaAccountUrlInput)
      row.appendChild(hiddenSocialMediaAccountTypeInput)
      row.appendChild(hiddenSocialMediaAccountHandleInput)
      row.appendChild(linkCol)
      row.appendChild(removeCol)

      entriesContainer.appendChild(row)
      
      // Reset the fields
      socialMediaAccountUrlField.value = ''
      socialMediaAccountTypeField.value = ''
      socialMediaAccountHandleField.value = ''
    }
  })

  document.getElementById('addProducerButton').addEventListener('click', function(event) {
    event.preventDefault()

    const firstNameField = document.getElementById('producer_first_name')
    const middleNameField = document.getElementById('producer_middle_name')
    const lastNameField = document.getElementById('producer_last_name')
    const roleField = document.getElementById('producer_role')
    const titleNameField = document.getElementById('producer_title_name')
    
    const firstName = firstNameField.value.trim()
    const middleName = middleNameField.value.trim()
    const lastName = lastNameField.value.trim()
    const role = roleField.value.trim()
    const titleName = titleNameField.value.trim()
    const characterName = ' '

    if (firstName) {
      const entriesContainer = document.getElementById('manualProducersEntriesContainer')

      const row = document.createElement('div')
      row.className = 'row mb-2'

      // Create a hidden input to store the first name value
      const hiddenFirstNameInput = document.createElement('input')
      hiddenFirstNameInput.type = 'hidden'
      hiddenFirstNameInput.name = 'first_names[]'
      hiddenFirstNameInput.value = firstName

      // Create a hidden input to store the middle name value
      const hiddenMiddleNameInput = document.createElement('input')
      hiddenMiddleNameInput.type = 'hidden'
      hiddenMiddleNameInput.name = 'middle_names[]'
      hiddenMiddleNameInput.value = middleName
      
      // Create a hidden input to store the last name value
      const hiddenLastNameInput = document.createElement('input')
      hiddenLastNameInput.type = 'hidden'
      hiddenLastNameInput.name = 'last_names[]'
      hiddenLastNameInput.value = lastName

      // Create a hidden input to store the title name value
      const hiddenRoleInput = document.createElement('input')
      hiddenRoleInput.type = 'hidden'
      hiddenRoleInput.name = 'roles[]'
      hiddenRoleInput.value = role

      // Create a hidden input to store the title name value
      const hiddenTitleNameInput = document.createElement('input')
      hiddenTitleNameInput.type = 'hidden'
      hiddenTitleNameInput.name = 'title_names[]'
      hiddenTitleNameInput.value = (titleName === null || titleName === '') ? ' ' : titleName

      // Create a hidden input to store the character name value
      const hiddenCharacterNameInput = document.createElement('input')
      hiddenCharacterNameInput.type = 'hidden'
      hiddenCharacterNameInput.name = 'character_names[]'
      hiddenCharacterNameInput.value = (characterName === null || characterName === '') ? ' ' : characterName

      // Create a visible text element to show name
      const nameDisplayText = document.createElement('div')
      nameDisplayText.className = 'ms-2'
      nameDisplayText.textContent = fullDescription({
        first_name: firstName,
        middle_name: middleName,
        last_name: lastName,
        role: role,
        title_name: titleName,
        character_name: characterName,
      });

      const nameCol = document.createElement('div')
      nameCol.className = 'col-4'
      nameCol.appendChild(nameDisplayText)

      const removeCol = document.createElement('div')
      removeCol.className = 'col-4'

      const removeIcon = document.createElement('i')
      removeIcon.className = 'bi bi-dash-circle'
      
      const removeButton = document.createElement('button')
      removeButton.type = 'button'
      removeButton.className = 'btn btn-danger'
      removeButton.appendChild(removeIcon)
      removeButton.appendChild(document.createTextNode(' Remove'))

      removeButton.addEventListener('click', function() {
        entriesContainer.removeChild(row)
      })

      removeCol.appendChild(removeButton)

      row.appendChild(hiddenFirstNameInput)
      row.appendChild(hiddenMiddleNameInput)
      row.appendChild(hiddenLastNameInput)
      row.appendChild(hiddenRoleInput)
      row.appendChild(hiddenTitleNameInput)
      row.appendChild(hiddenCharacterNameInput)
      row.appendChild(nameCol)
      row.appendChild(removeCol)

      entriesContainer.appendChild(row)
      
      // Reset the fields
      firstNameField.value = ''
      lastNameField.value = ''
      middleNameField.value = ''
      titleNameField.value = ''
    } else {
      console.error('Error:', error)
    }
  })

  document.getElementById('addDirectorButton').addEventListener('click', function(event) {
    event.preventDefault()
    
    const firstNameField = document.getElementById('director_first_name')
    const middleNameField = document.getElementById('director_middle_name')
    const lastNameField = document.getElementById('director_last_name')
    const roleField = document.getElementById('director_role')
    const titleNameField = document.getElementById('director_title_name')
    
    const firstName = firstNameField.value.trim()
    const middleName = middleNameField.value.trim()
    const lastName = lastNameField.value.trim()
    const role = roleField.value.trim()
    const titleName = titleNameField.value.trim()
    const characterName = ' '

    if (firstName) {
      const entriesContainer = document.getElementById('manualDirectorsEntriesContainer')

      const row = document.createElement('div')
      row.className = 'row mb-2'

      // Create a hidden input to store the first name value
      const hiddenFirstNameInput = document.createElement('input')
      hiddenFirstNameInput.type = 'hidden'
      hiddenFirstNameInput.name = 'first_names[]'
      hiddenFirstNameInput.value = firstName

      // Create a hidden input to store the middle name value
      const hiddenMiddleNameInput = document.createElement('input')
      hiddenMiddleNameInput.type = 'hidden'
      hiddenMiddleNameInput.name = 'middle_names[]'
      hiddenMiddleNameInput.value = middleName
      
      // Create a hidden input to store the last name value
      const hiddenLastNameInput = document.createElement('input')
      hiddenLastNameInput.type = 'hidden'
      hiddenLastNameInput.name = 'last_names[]'
      hiddenLastNameInput.value = lastName

      // Create a hidden input to store the title name value
      const hiddenRoleInput = document.createElement('input')
      hiddenRoleInput.type = 'hidden'
      hiddenRoleInput.name = 'roles[]'
      hiddenRoleInput.value = role

      // Create a hidden input to store the title name value
      const hiddenTitleNameInput = document.createElement('input')
      hiddenTitleNameInput.type = 'hidden'
      hiddenTitleNameInput.name = 'title_names[]'
      hiddenTitleNameInput.value = (titleName === null || titleName === '') ? ' ' : titleName

      // Create a hidden input to store the character name value
      const hiddenCharacterNameInput = document.createElement('input')
      hiddenCharacterNameInput.type = 'hidden'
      hiddenCharacterNameInput.name = 'character_names[]'
      hiddenCharacterNameInput.value = (characterName === null || characterName === '') ? ' ' : characterName

      // Create a visible text element to show name
      const nameDisplayText = document.createElement('div')
      nameDisplayText.className = 'ms-2'
      nameDisplayText.textContent = fullDescription({
        first_name: firstName,
        middle_name: middleName,
        last_name: lastName,
        role: role,
        title_name: titleName,
        character_name: characterName,
      });

      const nameCol = document.createElement('div')
      nameCol.className = 'col-4'
      nameCol.appendChild(nameDisplayText)

      const removeCol = document.createElement('div')
      removeCol.className = 'col-4'

      const removeIcon = document.createElement('i')
      removeIcon.className = 'bi bi-dash-circle'
      
      const removeButton = document.createElement('button')
      removeButton.type = 'button'
      removeButton.className = 'btn btn-danger'
      removeButton.appendChild(removeIcon)
      removeButton.appendChild(document.createTextNode(' Remove'))

      removeButton.addEventListener('click', function() {
        entriesContainer.removeChild(row)
      })

      removeCol.appendChild(removeButton)

      row.appendChild(hiddenFirstNameInput)
      row.appendChild(hiddenMiddleNameInput)
      row.appendChild(hiddenLastNameInput)
      row.appendChild(hiddenRoleInput)
      row.appendChild(hiddenTitleNameInput)
      row.appendChild(hiddenCharacterNameInput)
      row.appendChild(nameCol)
      row.appendChild(removeCol)

      entriesContainer.appendChild(row)
      
      // Reset the fields
      firstNameField.value = ''
      lastNameField.value = ''
      middleNameField.value = ''
      titleNameField.value = ''
    } else {
      console.error('Error:', error)
    }
  })

  document.getElementById('addWriterButton').addEventListener('click', function(event) {
    event.preventDefault()

    const firstNameField = document.getElementById('writer_first_name')
    const middleNameField = document.getElementById('writer_middle_name')
    const lastNameField = document.getElementById('writer_last_name')
    const roleField = document.getElementById('writer_role')
    const titleNameField = document.getElementById('writer_title_name')
    
    const firstName = firstNameField.value.trim()
    const middleName = middleNameField.value.trim()
    const lastName = lastNameField.value.trim()
    const role = roleField.value.trim()
    const titleName = titleNameField.value.trim()
    const characterName = ' '

    if (firstName) {
      const entriesContainer = document.getElementById('manualWritersEntriesContainer')

      const row = document.createElement('div')
      row.className = 'row mb-2'

      // Create a hidden input to store the first name value
      const hiddenFirstNameInput = document.createElement('input')
      hiddenFirstNameInput.type = 'hidden'
      hiddenFirstNameInput.name = 'first_names[]'
      hiddenFirstNameInput.value = firstName

      // Create a hidden input to store the middle name value
      const hiddenMiddleNameInput = document.createElement('input')
      hiddenMiddleNameInput.type = 'hidden'
      hiddenMiddleNameInput.name = 'middle_names[]'
      hiddenMiddleNameInput.value = middleName
      
      // Create a hidden input to store the last name value
      const hiddenLastNameInput = document.createElement('input')
      hiddenLastNameInput.type = 'hidden'
      hiddenLastNameInput.name = 'last_names[]'
      hiddenLastNameInput.value = lastName

      // Create a hidden input to store the title name value
      const hiddenRoleInput = document.createElement('input')
      hiddenRoleInput.type = 'hidden'
      hiddenRoleInput.name = 'roles[]'
      hiddenRoleInput.value = role

      // Create a hidden input to store the title name value
      const hiddenTitleNameInput = document.createElement('input')
      hiddenTitleNameInput.type = 'hidden'
      hiddenTitleNameInput.name = 'title_names[]'
      hiddenTitleNameInput.value = (titleName === null || titleName === '') ? ' ' : titleName

      // Create a hidden input to store the character name value
      const hiddenCharacterNameInput = document.createElement('input')
      hiddenCharacterNameInput.type = 'hidden'
      hiddenCharacterNameInput.name = 'character_names[]'
      hiddenCharacterNameInput.value = (characterName === null || characterName === '') ? ' ' : characterName

      // Create a visible text element to show name
      const nameDisplayText = document.createElement('div')
      nameDisplayText.className = 'ms-2'
      nameDisplayText.textContent = fullDescription({
        first_name: firstName,
        middle_name: middleName,
        last_name: lastName,
        role: role,
        title_name: titleName,
        character_name: characterName,
      });

      const nameCol = document.createElement('div')
      nameCol.className = 'col-4'
      nameCol.appendChild(nameDisplayText)

      const removeCol = document.createElement('div')
      removeCol.className = 'col-4'

      const removeIcon = document.createElement('i')
      removeIcon.className = 'bi bi-dash-circle'
      
      const removeButton = document.createElement('button')
      removeButton.type = 'button'
      removeButton.className = 'btn btn-danger'
      removeButton.appendChild(removeIcon)
      removeButton.appendChild(document.createTextNode(' Remove'))

      removeButton.addEventListener('click', function() {
        entriesContainer.removeChild(row)
      })

      removeCol.appendChild(removeButton)

      row.appendChild(hiddenFirstNameInput)
      row.appendChild(hiddenMiddleNameInput)
      row.appendChild(hiddenLastNameInput)
      row.appendChild(hiddenRoleInput)
      row.appendChild(hiddenTitleNameInput)
      row.appendChild(hiddenCharacterNameInput)
      row.appendChild(nameCol)
      row.appendChild(removeCol)

      entriesContainer.appendChild(row)
      
      // Reset the fields
      firstNameField.value = ''
      lastNameField.value = ''
      middleNameField.value = ''
      titleNameField.value = ''
    } else {
      console.error('Error:', error)
    }
  })

  document.getElementById('addActorButton').addEventListener('click', function(event) {
    event.preventDefault()

    const firstNameField = document.getElementById('actor_first_name')
    const middleNameField = document.getElementById('actor_middle_name')
    const lastNameField = document.getElementById('actor_last_name')
    const roleField = document.getElementById('actor_role')
    const characterNameField = document.getElementById('actor_character_name')
    
    const firstName = firstNameField.value.trim()
    const middleName = middleNameField.value.trim()
    const lastName = lastNameField.value.trim()
    const role = roleField.value.trim()
    const titleName = ' '
    const characterName = characterNameField.value.trim()

    if (firstName) {
      const entriesContainer = document.getElementById('manualActorsEntriesContainer')

      const row = document.createElement('div')
      row.className = 'row mb-2'

      // Create a hidden input to store the first name value
      const hiddenFirstNameInput = document.createElement('input')
      hiddenFirstNameInput.type = 'hidden'
      hiddenFirstNameInput.name = 'first_names[]'
      hiddenFirstNameInput.value = firstName

      // Create a hidden input to store the middle name value
      const hiddenMiddleNameInput = document.createElement('input')
      hiddenMiddleNameInput.type = 'hidden'
      hiddenMiddleNameInput.name = 'middle_names[]'
      hiddenMiddleNameInput.value = middleName
      
      // Create a hidden input to store the last name value
      const hiddenLastNameInput = document.createElement('input')
      hiddenLastNameInput.type = 'hidden'
      hiddenLastNameInput.name = 'last_names[]'
      hiddenLastNameInput.value = lastName

      // Create a hidden input to store the title name value
      const hiddenRoleInput = document.createElement('input')
      hiddenRoleInput.type = 'hidden'
      hiddenRoleInput.name = 'roles[]'
      hiddenRoleInput.value = role

      // Create a hidden input to store the title name value
      const hiddenTitleNameInput = document.createElement('input')
      hiddenTitleNameInput.type = 'hidden'
      hiddenTitleNameInput.name = 'title_names[]'
      hiddenTitleNameInput.value = (titleName === null || titleName === '') ? ' ' : titleName

      // Create a hidden input to store the character name value
      const hiddenCharacterNameInput = document.createElement('input')
      hiddenCharacterNameInput.type = 'hidden'
      hiddenCharacterNameInput.name = 'character_names[]'
      hiddenCharacterNameInput.value = (characterName === null || characterName === '') ? ' ' : characterName

      // Create a visible text element to show name
      const nameDisplayText = document.createElement('div')
      nameDisplayText.className = 'ms-2'
      nameDisplayText.textContent = fullDescription({
        first_name: firstName,
        middle_name: middleName,
        last_name: lastName,
        role: role,
        title_name: titleName,
        character_name: characterName,
      });

      const nameCol = document.createElement('div')
      nameCol.className = 'col-4'
      nameCol.appendChild(nameDisplayText)

      const removeCol = document.createElement('div')
      removeCol.className = 'col-4'

      const removeIcon = document.createElement('i')
      removeIcon.className = 'bi bi-dash-circle'
      
      const removeButton = document.createElement('button')
      removeButton.type = 'button'
      removeButton.className = 'btn btn-danger'
      removeButton.appendChild(removeIcon)
      removeButton.appendChild(document.createTextNode(' Remove'))

      removeButton.addEventListener('click', function() {
        entriesContainer.removeChild(row)
      })

      removeCol.appendChild(removeButton)

      row.appendChild(hiddenFirstNameInput)
      row.appendChild(hiddenMiddleNameInput)
      row.appendChild(hiddenLastNameInput)
      row.appendChild(hiddenRoleInput)
      row.appendChild(hiddenTitleNameInput)
      row.appendChild(hiddenCharacterNameInput)
      row.appendChild(nameCol)
      row.appendChild(removeCol)

      entriesContainer.appendChild(row)
      
      // Reset the fields
      firstNameField.value = ''
      lastNameField.value = ''
      middleNameField.value = ''
      characterNameField.value = ''
    } else {
      console.error('Error:', error)
    }
  })
}

export default class extends Controller {
  connect() {
    const controllerAction = this.data.get('action')
    switch (controllerAction) {
      case 'show':
        showConnect()
        break
      case 'edit':
        editConnect()
        break
      case 'public_edit':
        publicEditConnect()
        break
      case 'new':
        viewConnect()
        break
      case 'index':
        break
    }
  }
}
