$(function() {
  $('.truncate').on('click', function(ev) {
    ev.preventDefault()

    const $el = $(ev.target)
    const mode = $el.data('mode')

    if (mode === 'full') {
      $('.partial').hide()
      $('.full').show()
      $('.truncate').text('Show Less')
      $('.truncate').data('mode', 'partial')
    } else {
      $('.partial').show()
      $('.full').hide()
      $('.truncate').text('Show All')
      $('.truncate').data('mode', 'full')
    }
  })
})
