import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="search-criteria"
export default class extends Controller {
  static values = {
    params: Object,
  };

  static targets = [
    "titleName",
    "synopsis",
    "titleType",
    "genres",
    "soundFormat",
    "soundLanguage",
    "exclusivityType",
    "media",
    "territory",
    "language",
  ];

  connect() {
    this.highlightKeywordsInTitleName();
    this.highlightKeywordsInSynopsis();
    this.highlightTitleType();
    this.highlightGenres();
    this.highlightSoundFormats();
    this.highlightSoundLanguages();
    this.highlightExclusivityType();
    this.highlightMedias();
    this.highlightTerritories();
    this.highlightLanguages();
  }

  highlightKeywordsInTitleName() {
    this.highlightMatches(
      this.titleNameTargets,
      this.paramsValue.keyword,
      "Your search keyword matched the title name."
    );
  }

  highlightKeywordsInSynopsis() {
    this.highlightMatches(
      this.synopsisTargets,
      this.paramsValue.keyword,
      "Your search keyword matches part of the synopsis."
    );
  }

  highlightTitleType() {
    this.highlightMatches(this.titleTypeTargets, this.paramsValue.title_type, "Your search matches this title type.");
  }

  highlightGenres() {
    this.highlightMatches(this.genresTargets, this.paramsValue.genres, "Your search matches this genre.");
  }

  highlightSoundFormats() {
    this.highlightMatches(
      this.soundFormatTargets,
      this.paramsValue.sound_formats,
      "Your search matches this sound format."
    );
  }

  highlightSoundLanguages() {
    this.highlightMatches(
      this.soundLanguageTargets,
      this.paramsValue.sound_languages,
      "Your search matches this sound language."
    );
  }

  highlightExclusivityType() {
    // Setting the license period start date excludes all titles with
    // exclusive sales, so let's make that clear in the tooltip title.
    const tooltipTitle =
      this.paramsValue.license_period_start_date !== null
        ? "You searched for the license period start date, which only fetches non-exclusive sales."
        : "You searched for this exclusivity type.";

    this.highlightMatches(this.exclusivityTypeTargets, this.paramsValue.exclusivity_type, tooltipTitle);
  }

  highlightMedias() {
    this.highlightMatches(this.mediaTargets, this.paramsValue.medias, "Your search matches this media.");
  }

  highlightTerritories() {
    this.highlightMatches(this.territoryTargets, this.paramsValue.territories, "Your search matches this territory.");
  }

  highlightLanguages() {
    this.highlightMatches(this.languageTargets, this.paramsValue.languages, "Your search matches this language.");
  }

  highlightMatches(targets, value, tooltipTitle) {
    if (value === null || value === "" || value.length === 0) {
      return;
    }

    let regexPattern = Array.isArray(value) ? value.join("|") : value;
    regexPattern = regexPattern.replace(/[.*+?^${}()[\]\\]/g, "\\$&");

    const regex = new RegExp(`\\b${regexPattern}`, "gi");

    targets.forEach((target) => {
      target.innerHTML = target.innerHTML.replace(
        regex,
        `<span class='bg-primary text-white' title='${tooltipTitle}'>$&</span>`
      );
    });
  }
}
